@import '@ra/lib-common/styles/variables/fonts';

.filter__button-toggles {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  & button {
    background: transparent;
    border: 1px solid #ffffff;
    padding: 8px 12px;
    margin: 0 8px 8px 0;
    cursor: pointer;

    font-family: var(--sans-serif);
    font-size: 14px;
    color: #ffffff;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &.-selected {
      background-color: #0363ae;
    }
  }
}
