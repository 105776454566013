@import '@ra/lib-common/styles/variables/colors';

.toggle-button {
  cursor: pointer;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid var(--gray);
  transition: box-shadow 100ms ease-out, border-color 100ms ease-out;

  & .toggle-button__switch {
    position: absolute;
    top: 3px;
    bottom: 3px;
    border-radius: 2px;
    background-color: var(--faded-blue);
    transition: background-color 100ms ease-out, border-color 100ms ease-out;
  }

  &:hover {
    border-color: #bbb;
    box-shadow: 1px 1px 1px hsl(0deg 0% 70% / 0.333);

    & .toggle-button__switch {
      background-color: var(--blue);
    }
  }

  & .toggle-button__options {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 6px;
    z-index: 1;

    & button {
      color: #bbb;
      font-size: 12px;
      font-weight: 500;
      padding: 0 2px 0;
      transition: color 100ms ease-out;
      user-select: none;
      box-sizing: border-box;

      &.option--selected {
        color: white;
      }
    }
  }
}
