/**
 * Chart resizing
 */

.chart-resize-window {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
