@import '@ra/lib-common/styles/variables/colors';

/**
 * Styles for charts. These should be additive. We need SVG to render
 * most styles so that exported images display correctly
 */

.axis__label,
.axis__tick {
  font-weight: 500;
  letter-spacing: 0.5px;
}

.axis__label {
  font-size: 15px;
}

.chart.-small .axis.-x .axis__meter {
  stroke-opacity: 1;
}

.chart.-small .axis .axis__line {
  opacity: 0;
}

.chart.-small .grid__columns,
.chart.-small .grid__frame__left,
.chart.-small .grid__frame__right {
  opacity: 0;
}

.chart.-small .grid {
  stroke-dasharray: 2 4;
  stroke: #d9d9d9;
  stroke-width: 2;
}

.chart.-small .axis__tick {
  font-size: 11px;
}

/**
 * Special display properties based on size
 */

.chart-show-small,
.chart-show-large {
  opacity: 0;
}

.chart.-small .chart-show-small,
.chart.-large .chart-show-large {
  opacity: 1;
}

/**
* Scrubbers show up on line charts in Asset Allocation
*/

.scrubber__knob {
  fill: red;
}

.scrubber__knob.-dragging,
.scrubber__knob:hover {
  cursor: col-resize;

  & .scrubber__label {
    transform: translate(0, -24px) scale(1.5);
  }

  & .scrubber__handle r {
    r: 8;
  }
}

.scrubber__handle {
  fill: white;
  r: 6;
  stroke: var(--blue);
  stroke-width: 2px;
  transition: all 200ms ease-out;
}

.scrubber__label {
  clip: rect(0, 0, 0, 0);
  opacity: 1;
  transform: translate(0, -18px);
  stroke: var(--blue);
  transition: all 200ms ease-out;
  text-anchor: middle;
  pointer-events: none;
  user-select: none;
  letter-spacing: 1px;

  & rect {
    fill: rgba(255, 255, 255, 0.8);
    stroke: var(--blue);
    stroke-width: 1.5px;
  }
}

.scrubber line {
  stroke: var(--blue);
  stroke-width: 2px;
}

/**
 * Animations
 */

@keyframes chart-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.chart {
  animation: 0.4s chart-fade-in;
}
